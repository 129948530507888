@import "@fontsource/ruda";

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 25px;
  @apply text-gray-700;
}
